import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";

import { CloseIcon24 } from "../../icons";
import Button from "../Button/Button";

const LightBox = ({ media, title }, ref) => {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    close: () => {
      setOpen(false);
    },
    show: () => {
      setOpen(true);
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="fixed inset-0 z-10 overflow-y-auto bg-black">
          <div className="text-white px-60 py-35 flex justify-between items-center">
            {title}
            <Button
              onClick={() => {
                setOpen(false);
              }}
              onlyIcon
              title="Schließen"
              color="white"
            >
              <CloseIcon24 />
            </Button>
          </div>
          <div className="px-175 py-60">
            <Dialog.Panel className="relative transform overflow-hidden aspect-[16/9]">
              {media}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LightBox.propTypes = {
  media: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

export default forwardRef(LightBox);
