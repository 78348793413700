import React from "react";

import PropTypes from "prop-types";
import MasonryComponent from "react-masonry-css";

import * as styles from "./Masonry.module.scss";

const Masonry = (props) => {
  const {
    children,
    breakpointCols,
    className,
    columnClassName,
  } = props;

  return (
    <MasonryComponent
      breakpointCols={breakpointCols}
      className={className}
      columnClassName={columnClassName}
    >
      {children}
    </MasonryComponent>
  );
};

Masonry.propTypes = {
  breakpointCols: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnClassName: PropTypes.string,
};

Masonry.defaultProps = {
  breakpointCols: 3,
  className: styles.masonryGrid,
  columnClassName: styles.masonryGrid_column,
};

export default Masonry;
