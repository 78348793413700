import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import {
  ArrowRightIcon24,
  FacebookIcon24, InstagramIcon24,
  ShareLinkIcon24,
} from "../../icons";

const Sharing = ({ title, shareTitle, shareUrl }) => {
  const url = shareUrl || ((typeof window !== "undefined") ? window.location.href : null);
  const { t } = useTranslation();

  return (
    <div>
      {title && <div className="text-body2 text-dark-grey mb-18">{title}</div>}
      <div className="flex gap-x-24">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              <ShareLinkIcon24 />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 bg-black text-white cursor-pointer">
              <div className="p-1">
                <Menu.Item className="whitespace-nowrap">
                  {({ active }) => (
                    <div>
                      <button
                        className="p-10"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                        }}
                      >
                        <ArrowRightIcon24 /> {t("copyLink")}
                      </button>
                      <div className="p-10 bg-white text-black">
                        {url}
                      </div>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a
          href={"https://www.facebook.com/sharer.php?u={url}".replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon24 />
        </a>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a
          href={"https://www.instagram.com/?url={url}".replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon24 />
        </a>
      </div>
    </div>
  );
};

Sharing.propTypes = {
  shareTitle: PropTypes.string.isRequired,
  shareUrl: PropTypes.string,
  title: PropTypes.string,
};

Sharing.defaultProps = {
  shareUrl: null,
  title: "",
};

export default Sharing;
