import React from "react";

import PropTypes from "prop-types";

import { PlayIcon54 } from "../../icons";

import * as styles from "./VideoPreview.module.scss";

const VideoPreview = ({ previewImageUrl }) => (

  <div className={styles.videoPreview} style={{ backgroundImage: `url(${previewImageUrl})` }}>
    <PlayIcon54 />
  </div>
);

VideoPreview.propTypes = {
  previewImageUrl: PropTypes.string.isRequired,
};

export default VideoPreview;
