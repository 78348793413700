import React from "react";

import PropTypes from "prop-types";

const QuickFacts = ({ children }) => (
  <div className="grid grid-cols-2 gap-y-12 gap-x-18 lg:grid-cols-1">
    {children}
  </div>
);

QuickFacts.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default QuickFacts;
