import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const GetCallToActionContext = (cta) => {
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          pageContext
        }
      }
    }
  `);

  let result = null;

  const pages = data.allSitePage.nodes;
  const { language } = useI18next();

  const lang = language === "de-DE" ? "" : `/${language}`;

  if (cta?.linkType === "external") {
    return {
      blank: cta?.blank || false,
      path: `${lang}${cta?.href}` || "/",
      title: cta?.title || "",
    };
  }

  result = pages.find((page) => page.pageContext.id === cta?._rawInternalLink?.id);

  return {
    path: `${lang}${result?.path}` || "/",
    title: cta?.title || "",
  };
};

export default GetCallToActionContext;
