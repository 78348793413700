import React, { useRef } from "react";

import PropTypes from "prop-types";

import useMediaQuery from "../../hooks/useMediaQuery";
import Consent from "../consent/consent";
import LightBox from "../LightBox/LightBox";

const MediaCard = ({ children, title }) => {
  const mediaModal = useRef();

  let embedVideoUrl;

  if (children.props?.videoUrl) {
    embedVideoUrl = children.props?.videoUrl;

    if (embedVideoUrl.indexOf("&") !== -1) {
      embedVideoUrl = embedVideoUrl.substring(0, embedVideoUrl.indexOf("&"));
    }

    embedVideoUrl = embedVideoUrl
      .replace("youtube.com", "youtube-nocookie.com")
      .replace("watch?v=", "embed/");

    if (embedVideoUrl.indexOf("youtu.be") !== -1) {
      embedVideoUrl = `https://www.youtube-nocookie.com/embed/${embedVideoUrl.substring(embedVideoUrl.lastIndexOf("/") + 1)}`;
    }
  }

  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <Consent type="video">
      {(isMobile && children.props?.videoUrl) && (
        <iframe
          title="video"
          className="border-0 h-full w-full aspect-[16/9]"
          src={embedVideoUrl}
          allowFullScreen
        />
      )}
      {(!isMobile) && (
        <>
          <div className="aspect-[16/9]">
            <div
              className="h-full cursor-pointer"
              onClick={() => {
                mediaModal.current.show();
              }}
              role="presentation"
            >
              {children}
            </div>
            <div className="text-small bg-black text-white px-18 py-10">
              {title}
            </div>
          </div>
          <LightBox
            media={children.props?.videoUrl
              ? (
                <iframe
                  title="video"
                  className="border-0 h-full w-full"
                  src={embedVideoUrl}
                  allowFullScreen
                />
              )
              : children}
            ref={mediaModal}
            title={title}
          />
        </>
      )}
    </Consent>
  );
};

MediaCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

MediaCard.defaultProps = {
  title: "",
};

export default MediaCard;
