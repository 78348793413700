import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const QuickFactsItem = ({ children, title }) => (
  <div className="gap-y-3 grid grid-cols-1">
    <div className="text-label">{title}</div>
    {React.Children.toArray(children).map(
      (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classNames({ "text-body2": typeof child === "string" })} key={index}>
          {child}
        </div>
      ),
    )}
  </div>
);

QuickFactsItem.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.string,
    ]),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default QuickFactsItem;
