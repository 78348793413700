import React from "react";

import PropTypes from "prop-types";

import { ExternalLinkIcon24 } from "../../icons";
import GetInternalLinkContext from "../../templates/utils/GetInternalLinkContext";
import Button from "../Button/Button";

const QuickFactsLink = ({ data, color }) => {
  let url = "#";
  let title = "";
  let link = null;

  switch (data.linkType) {
    case "internal":
      link = GetInternalLinkContext({ id: data._rawInternalLink?.id });
      url = link.path;
      title = link.title;
      break;
    case "external":
      url = data.href;
      title = data.title;
      break;
    case "file":
      url = data.file.asset.url;
      title = data.title;
      break;
    default:
      url = "#";
      title = "";
  }

  return (
    !color ? (
      <Button variant="secondary" title={title} href={url}>
        <ExternalLinkIcon24 /> {title}
      </Button>
    ) : (
      <Button variant="secondary" title={title} href={url} color={color}>
        <ExternalLinkIcon24 /> {title}
      </Button>
    )
  );
};

QuickFactsLink.propTypes = {
  color: PropTypes.string,
  data: PropTypes.node.isRequired,
};

QuickFactsLink.defaultProps = {
  color: null,
};

export default QuickFactsLink;
