import React from "react";

import PropTypes from "prop-types";

import * as styles from "./Eyecatcher.module.scss";

const Eyecatcher = (props) => {
  const {
    logo,
    headline,
    description,
    button,
  } = props;

  return (
    <div className={styles.eyecatcher}>
      {logo && <div className="text-white mb-30">{logo}</div>}
      <h4 className="mb-12">{headline}</h4>
      {description && <div className="mb-30 text-body1">{description}</div>}
      {button}
    </div>
  );
};

Eyecatcher.propTypes = {
  button: PropTypes.node,
  description: PropTypes.node,
  headline: PropTypes.string,
  logo: PropTypes.node,
};

Eyecatcher.defaultProps = {
  button: null,
  description: null,
  headline: "",
  logo: null,
};

export default Eyecatcher;
