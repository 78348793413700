import React from "react";

import PropTypes from "prop-types";

const DownloadGrid = ({ children, title }) => (
  <div className="grid grid-cols-12 py-36 lg:py-45">
    <div className="col-span-12 h3 mb-24 lg:col-start-2 lg:col-span-7 lg:px-30 lg:mb-42">{title}</div>
    <div className="col-span-12">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-24 gap-x-60">
        {React.Children.toArray(children).map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-span-1 odd:mt-42" key={child.props.title + index}>
            {child}
          </div>
        ))}
      </div>
    </div>
  </div>
);

DownloadGrid.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DownloadGrid;
