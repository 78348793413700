/* eslint-disable */
import {
  AddIcon24,
  ArrowLeftIcon18,
  ArrowRightIcon18,
  ArrowRightIcon24,
  CalendarIcon24,
  CarIcon54,
  CheckboxBlackCheckedIcon18,
  CheckboxBlackIcon18,
  CheckboxWhiteCheckedIcon18,
  CheckboxWhiteIcon18,
  CheckIcon24,
  ChevronDownIcon18,
  ChevronDownIcon24,
  ChevronLeftIcon24,
  ChevronRightIcon24,
  ChevronUpIcon18,
  ChevronUpIcon24,
  CloseIcon24,
  CollapseIcon24,
  CollectionIcon54,
  CommunityIcon54,
  DeleteIcon24,
  DigitalExhibitionIcon36,
  DownloadIcon24,
  EditIcon24,
  EnvelopeIcon36,
  ExpandIcon24,
  ExternalLinkIcon24,
  FacebookIcon18,
  FacebookIcon24,
  FavoriteIcon18,
  FavoriteIcon24,
  FilePdfIcon36,
  FilterIcon24,
  ImageAddIcon36,
  ImageSearchIcon36,
  InstagramIcon18,
  LogoutIcon24,
  MenuIcon24,
  MuseumIcon54,
  OpeningIcon24,
  PlayIcon24,
  PlayIcon54,
  PoiIcon54,
  ProfileIcon24,
  ProfileIcon36,
  QRCodeIcon24,
  QuizIcon54,
  SearchIcon24,
  ShareIcon24,
  ShareLinkIcon24,
  TicketIcon24,
  TimeIcon24,
  TourIcon54,
  TrainIcon54,
  TwitterIcon18,
  TwitterIcon24,
  WebsiteIcon24,
  WheelchairIcon54,
  YoutubeIcon18,
  InstagramIcon24,
  CalendarIcon18,
  TimeIcon18,
  TicketIcon18,
  WheelchairIcon24,
  WheelchairIcon18,
} from "../../icons";

const GetIconComponents = () => {
  const components = {
    "ArrowLeftIcon18": ArrowLeftIcon18,
    "ArrowRightIcon18": ArrowRightIcon18,
    "CheckboxBlackIcon18": CheckboxBlackIcon18,
    "CheckboxBlackCheckedIcon18": CheckboxBlackCheckedIcon18,
    "CheckboxWhiteIcon18": CheckboxWhiteIcon18,
    "CheckboxWhiteCheckedIcon18": CheckboxWhiteCheckedIcon18,
    "ChevronDownIcon18": ChevronDownIcon18,
    "ChevronUpIcon18": ChevronUpIcon18,
    "FacebookIcon18": FacebookIcon18,
    "FavoriteIcon18": FavoriteIcon18,
    "InstagramIcon18": InstagramIcon18,
    "TwitterIcon18": TwitterIcon18,
    "YoutubeIcon18": YoutubeIcon18,
    "AddIcon24": AddIcon24,
    "ArrowRightIcon24": ArrowRightIcon24,
    "CalendarIcon24": CalendarIcon24,
    "CheckIcon24": CheckIcon24,
    "ChevronDownIcon24": ChevronDownIcon24,
    "ChevronLeftIcon24": ChevronLeftIcon24,
    "ChevronRightIcon24": ChevronRightIcon24,
    "ChevronUpIcon24": ChevronUpIcon24,
    "CloseIcon24": CloseIcon24,
    "DeleteIcon24": DeleteIcon24,
    "DownloadIcon24": DownloadIcon24,
    "EditIcon24": EditIcon24,
    "ExpandIcon24": ExpandIcon24,
    "ExternalLinkIcon24": ExternalLinkIcon24,
    "FacebookIcon24": FacebookIcon24,
    "FavoriteIcon24": FavoriteIcon24,
    "FilterIcon24": FilterIcon24,
    "LogoutIcon24": LogoutIcon24,
    "MenuIcon24": MenuIcon24,
    "OpeningIcon24": OpeningIcon24,
    "PlayIcon24": PlayIcon24,
    "ProfileIcon24": ProfileIcon24,
    "QRCodeIcon24": QRCodeIcon24,
    "SearchIcon24": SearchIcon24,
    "ShareIcon24": ShareIcon24,
    "ShareLinkIcon24": ShareLinkIcon24,
    "TicketIcon24": TicketIcon24,
    "TimeIcon24": TimeIcon24,
    "TwitterIcon24": TwitterIcon24,
    "WebsiteIcon24": WebsiteIcon24,
    "CollapseIcon24": CollapseIcon24,
    "DigitalExhibitionIcon36": DigitalExhibitionIcon36,
    "EnvelopeIcon36": EnvelopeIcon36,
    "FilePdfIcon36": FilePdfIcon36,
    "ImageAddIcon36": ImageAddIcon36,
    "ImageSearchIcon36": ImageSearchIcon36,
    "ProfileIcon36": ProfileIcon36,
    "CarIcon54": CarIcon54,
    "CollectionIcon54": CollectionIcon54,
    "CommunityIcon54": CommunityIcon54,
    "MuseumIcon54": MuseumIcon54,
    "PoiIcon54": PoiIcon54,
    "QuizIcon54": QuizIcon54,
    "TourIcon54": TourIcon54,
    "TrainIcon54": TrainIcon54,
    "WheelchairIcon54": WheelchairIcon54,
    "PlayIcon54": PlayIcon54,
    "InstagramIcon24": InstagramIcon24,
    "CalendarIcon18": CalendarIcon18,
    "TimeIcon18": TimeIcon18,
    "TicketIcon18": TicketIcon18,
    "WheelchairIcon24": WheelchairIcon24,
    "WheelchairIcon18": WheelchairIcon18,
  };

  return {
    components: components || "/",
  };
};

export default GetIconComponents;
