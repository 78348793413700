import React from "react";

import PropTypes from "prop-types";

const DetailPageHeader = (props) => {
  const {
    type,
    headline,
    sharing,
    image,
    children,
  } = props;

  return (
    <div className="px-18">
      <div className="my-36 grid grid-cols-1 lg:grid-cols-2 lg:mt-72 lg:mb-90">
        <div className="col-span-1 lg:pr-30 h-auto max-w-full">
          {image}
        </div>
        <div className="col-span-1 mt-24 lg:mt-0 lg:pl-30 lg:self-end lg:pb-60">
          <div className="text-lead mb-9">{type}</div>
          <h1 className="h2">{headline}</h1>
          {children && (<div className="mt-24">{children}</div>)}
          <div className="mt-24 lg:mt-36">
            {sharing}
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPageHeader.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  sharing: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

DetailPageHeader.defaultProps = {
  children: null,
};

export default DetailPageHeader;
