import React, { useEffect, useState } from "react";

import BlockContent from "@sanity/block-content-to-react";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";
import Zoom from "react-medium-image-zoom";
// eslint-disable-next-line import/no-unresolved
import "react-medium-image-zoom/dist/styles.css";

import Button from "../../components/Button/Button";
import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import DownloadGrid from "../../components/DownloadGrid/DownloadGrid";
import Eyecatcher from "../../components/Eyecatcher/Eyecatcher";
import Masonry from "../../components/Masonry/Masonry";
import MediaCard from "../../components/MediaCard/MediaCard";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import QuickFactsLink from "../../components/QuickFactsLink/QuickFactsLink";
import Section from "../../components/Section/Section";
import Sharing from "../../components/Sharing/Sharing";
import VideoPreview from "../../components/VideoPreview/VideoPreview";
import { setIsAuthenticated, setUser } from "../../features/IdentitySlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import ArtworkCard from "../Artwork/ArtworkCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";
import GetIconComponents from "../utils/GetIconComponents";

const ExhibitionPage = ({ data: { page } }) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation();
  const lang = page.i18nLang || "de-DE";
  const [isOpen, setIsOpen] = useState(false);

  const toggleZoom = (index) => {
    setIsOpen(isOpen === index ? -1 : index);
  };

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <DetailPageHeader
        headline={page.title.trim() + (page.year ? `, ${page.year}` : "")}
        image={page.images.images
          && page.images.images.length > 0
          && page.images?.images[0]?.asset && (
            <GatsbyImage
              alt={page.images.images[0].alt}
              image={page.images.images[0].asset.gatsbyImageData}
            />
        )}
        sharing={(
          <Sharing
            shareTitle={page.title}
            title={`${t("share")}:`}
          />
        )}
        type={t(page.type?.type)}
      />
      <div className="grid grid-cols-12 mx-18">
        <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
          {page._rawDescription && (
            <div className="grid grid-cols-12 lg:grid-cols-9">
              <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                {page.headline ? (
                  page.headline
                ) : (
                  t("aboutTheExhibition")
                )}
              </div>
              <div className="col-span-12 lg:col-span-8 mt-24 lg:mt-42 blockcontent">
                {page._rawDescription && (
                  <BlockContent blocks={page._rawDescription} />
                )}
              </div>
            </div>
          )}
          {page.eyecatcher
            && (
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-11 mt-24 lg:mt-42">
                  <Eyecatcher
                    headline={page.eyecatcher?.headline}
                    button={
                      page.eyecatcher?.callToAction
                      && (
                        <Button
                          color="white"
                          title="Button"
                          href={GetCallToActionContext(page.eyecatcher).path}
                        >{page.eyecatcher?.callToAction?.title}
                        </Button>
                      )
                    }
                    description={<BlockContent blocks={page.eyecatcher?._rawText} />}
                    logo={
                      React.createElement(
                        GetIconComponents().components[page.eyecatcher?.Icon?.component],
                      )
                    }
                  />
                </div>
              </div>
            )}
          {page.videos?.length > 0 && (
            <div className="text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
              <DownloadGrid title="Videos">
                {page.videos?.map((video) => (
                  <MediaCard>
                    <VideoPreview
                      previewImageUrl={video.thumbnail}
                      videoUrl={video.url}
                    />
                  </MediaCard>
                ))}
              </DownloadGrid>
            </div>
          )}
          {page.images?.images.length > 0 && (
            <div className="grid grid-cols-12 lg:grid-cols-9 mt-40">
              <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                {t("documentation")}
              </div>
              <div className="col-span-12 lg:col-span-8 mt-24 lg:mt-42">
                <Masonry breakpointCols={isMobile ? 1 : 2}>
                  {page.images?.images
                    .map(
                      (image, index) => (
                        image.asset && image.asset.gatsbyImageData && (
                          // eslint-disable-next-line max-len
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                          <div onClick={() => {
                            toggleZoom(index);
                          }}
                          >
                            <dialog open={isOpen === index} className="h-screen p-18">
                              <GatsbyImage
                                alt={image.alt}
                                image={image.asset.gatsbyImageData}
                                imgStyle={{ objectFit: "contain" }}
                              />
                              <div
                                className="text-body2-mobile lg:text-body2-desktop text-left w-auto"
                              >{image.alt}
                              </div>
                            </dialog>
                            <GatsbyImage
                              alt={image.alt}
                              image={image.asset.gatsbyImageData}
                            />
                          </div>
                        )
                      ),
                    )}
                </Masonry>
              </div>
            </div>
          )}
          {page.sponsors.length > 0 && (
            <div className="grid grid-cols-12 lg:grid-cols-9 mt-40">
              <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                {t("Supported by")}
              </div>
              <div className="col-span-12 lg:col-span-8 mt-24 lg:mt-42">
                <div
                  className="grid grid-cols-2 gap-12 md:grid-cols-3 xl:grid-cols-5 flex flex-wrap gap-30"
                >
                  {page.sponsors
                    .map(
                      (sponsor, index) => (
                        sponsor.image.asset && sponsor.image.asset.gatsbyImageData && (
                          <a href={sponsor.url} className="flex justify-center items-center">
                            <GatsbyImage
                              alt={sponsor.image.alt}
                              image={sponsor.image.asset.gatsbyImageData}
                              imgStyle={{ objectFit: "contain" }}
                            />
                          </a>
                        )
                      ),
                    )}
                </div>
              </div>
            </div>
          )}
          {page.collection?.artwork.length > 0 && (
            <Section>
              <div className="grid grid-cols-12 lg:grid-cols-9">
                <div
                  className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase mt-72 lg:mt-90"
                >
                  {t("highlights")}
                </div>
                <div className="col-span-12 lg:col-span-8 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
                  <Masonry breakpointCols={isMobile ? 2 : 3}>
                    {page.collection?.artwork.slice(0, 24)
                      .map(
                        (artwork, index) => (
                          <ArtworkCard data={artwork} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                        ),
                      )}
                  </Masonry>
                </div>
              </div>
            </Section>
          )}

        </div>
        <div className="col-span-12 order-1 mb-40 lg:order-2 lg:col-span-3 lg:mb-0">
          <QuickFacts>
            <QuickFactsItem title={t("period")}>
              <div className="text-body2 flex">
                {new Date(page.startdate).toLocaleDateString(
                  lang,
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  },
                )}-
                {new Date(page.enddate).toLocaleDateString(
                  lang,
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  },
                )}
              </div>
            </QuickFactsItem>
            <QuickFactsItem title={t("format")}>
              {t(page.type?.type)}
            </QuickFactsItem>
            {page.room && (
              <QuickFactsItem title={t("room")}>
                {page.room}
              </QuickFactsItem>
            )}
            {page.furtherLinks.length > 0 && (
              <QuickFactsItem title={t("furtherLinks")}>
                {page.furtherLinks?.map((link) => (
                  <QuickFactsLink data={link} />
                ))}
              </QuickFactsItem>
            )}
            {page.artists && (
              <QuickFactsItem title={t("artists")}>
                {page.artists?.map((artist) => (
                  artist.name
                ))}
              </QuickFactsItem>
            )}
          </QuickFacts>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityExhibition(id: {eq: $id}) {
      id
      i18n_lang
      title
      type {
        id
        type
      }
      topic
      headline
      images {
        images {
          asset {
            url
            gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 1920)
          }
          alt
        }
      }
      sponsors {
        image {
          asset {
            url
            gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 200)
          }
          alt
        }
        url
      }
      startdate
      enddate
      room
      artists {
        name
        id
      }
      _rawDescription
      Metadata {
        ...MetaData
      }
      collection {
        id
        artwork {
          ...ArtworkCard
        }
      }
      furtherLinks {
        title
        linkType
        href
        _rawInternalLink
        file {
          asset {
            assetId
            path
            url
          }
        }
      }
      eyecatcher {
        headline
        _rawText
        Icon {
          component
          icon {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 720)
            }
          }
        }
        callToAction {
          linkType
          blank
          title
          href
          _rawInternalLink(resolveReferences: {maxDepth: 1})
        }
      }
      collection {
        id
        artwork {
          ...ArtworkCard
        }
      }
    }
  }
`;

ExhibitionPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ExhibitionPage;
